@import "normalize.css";

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0 !important;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 14px;
    letter-spacing: 0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-user-select: none;
    overflow: visible !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input::-ms-clear,
input::-ms-reveal {
    display: none;
}
